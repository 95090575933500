import { AxiosError } from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../api';
import { RootState } from '../store';
import { DistrictType } from '../types/reducers/districts';
import {
    MapDataState,
    MapCoordinates,
    ScoresType,
    FetchMapDataType,
    ProjectFetchDataType,
    WastewaterPipeFeature,
    DistrictPipeDataType,
    ImpactScoreDataState,
} from '../types/reducers/mapData';
import { ProjectDataType, ProjectStatusType } from '../types/reducers/projects';
import { ThunkType, WeightsType } from '../types/types';
import {
    defaultProjectWeights,
    initialProfileScoreState,
    initialViewport,
    mapHeight,
    mapWidth,
    PROJECT_STATUS,
} from '../util/constants';
import {
    calcProfileScores,
    doWeightsMatch,
    getErrorMessage,
    getProjectWeights,
    getUniqueWeightLimitWarnings,
    pipeFeaturesToGeojson,
} from '../util/utils';
import createViewPortFromBounds from '../util/createViewPortFromBounds';
import { startFetchProject, completeFetchProject, failFetchProject } from './project';
import { createClearOnLogout } from './auth';
import { PipesQueryType } from '../api';

const initialState: MapDataState = {
    data: {
        impactScores: { district: null, scores: {}, weights: defaultProjectWeights, loading: false },
        pipeData: { district: null, features: [], assetCategory: null, loading: false },
    },
    viewport: initialViewport,
    pin: undefined,
    loading: false,
    loadingStartTime: null,
    error: '',
    scored: true,
    extent: undefined,
};

const clearOnLogout = createClearOnLogout<MapDataState>(initialState);

export const mapDataSlice = createSlice({
    name: 'mapData',
    initialState,
    reducers: {
        setViewport: (state: MapDataState, { payload }) => {
            state.viewport = payload;
        },
        setMapExtent: (state: MapDataState, { payload }) => {
            state.extent = payload;
        },
        clearMapData: () => initialState,
        startFetchImpactScores: (state: MapDataState) => {
            state.scored = false;
            state.data.impactScores = { ...initialState.data.impactScores, ...{ loading: true } };
            state.error = '';
            state.loadingStartTime = new Date().getTime();
        },
        completeFetchImpactScores: (state: MapDataState, { payload }: PayloadAction<ImpactScoreDataState>) => {
            state.scored = true;
            state.data.impactScores = payload;
            state.loadingStartTime = null;
        },
        failFetchImpactScores: (state: MapDataState, { payload }: PayloadAction<string>) => {
            state.data.impactScores.loading = false;
            state.error = payload;
            state.loadingStartTime = null;
        },
        startFetchPipeData: (state: MapDataState) => {
            state.data.pipeData = { ...initialState.data.pipeData, ...{ loading: true } };
            state.error = '';
            state.loadingStartTime = new Date().getTime();
        },
        completeFetchPipeData: (state: MapDataState, { payload }: PayloadAction<DistrictPipeDataType>) => {
            state.data.pipeData = payload.pipeDataState;
            state.extent = payload.extent;
            state.loadingStartTime = null;
        },
        failFetchPipeData: (state: MapDataState, { payload }: PayloadAction<string>) => {
            state.data.pipeData.loading = false;
            state.error = payload;
            state.loadingStartTime = null;
        },
        startSetMapSearchPoint: (state: MapDataState) => {
            state.loading = true;
            state.error = '';
        },
        completeSetMapSearchPoint: (state: MapDataState, { payload }: PayloadAction<MapCoordinates>) => {
            const { latitude, longitude, extent } = payload;
            state.viewport.latitude = latitude;
            state.viewport.longitude = longitude;
            state.extent = extent;
            state.pin = [longitude, latitude];
            state.loading = false;
        },
        failSetMapSearchPoint: (state: MapDataState, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setMapDataUnscored: (state: MapDataState) => {
            state.scored = false;
        },
        clearMapSearchPoint: (state: MapDataState) => {
            state.pin = undefined;
        },
    },
    extraReducers: clearOnLogout,
});

export const {
    setViewport,
    setMapExtent,
    clearMapData,
    startFetchImpactScores,
    completeFetchImpactScores,
    failFetchImpactScores,
    startFetchPipeData,
    completeFetchPipeData,
    failFetchPipeData,
    startSetMapSearchPoint,
    completeSetMapSearchPoint,
    failSetMapSearchPoint,
    setMapDataUnscored,
    clearMapSearchPoint,
} = mapDataSlice.actions;

export const setMapViewport =
    (extent: [number, number, number, number]): ThunkType =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        const viewport = createViewPortFromBounds({
            bounds: extent,
            width: mapWidth,
            height: mapHeight,
        });

        dispatch(setViewport(viewport));
    };

// Checks what we need to fetch and dispatches the correct reducer to fetch only what we need
export const fetchMapData =
    (project: ProjectDataType, { district_name, weights }: FetchMapDataType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        const { mapData } = getState();
        const { data } = mapData;

        const areImpactScoresCurrent =
            !data.impactScores.loading &&
            doWeightsMatch(data.impactScores.weights, project) &&
            project.district_name === data.impactScores.district;
        const arePipeDataCurrent =
            !data.pipeData.loading &&
            project.district_name === data.pipeData.district &&
            project.asset_category == data.pipeData.assetCategory;

        if (!arePipeDataCurrent || !areImpactScoresCurrent) {
            if (project.asset_category === 'Wastewater') {
                if (project.wastewater_system_name)
                    dispatch(
                        fetchWastewaterPipeData({
                            district: district_name,
                            wastewater_system: project.wastewater_system_name,
                            projectUniversalId: project.universal_id,
                        }),
                    );
            } else {
                if (!areImpactScoresCurrent) {
                    dispatch(fetchImpactScores(district_name, weights));
                }
                if (!arePipeDataCurrent) {
                    dispatch(fetchWaterPipeData(district_name));
                }
            }
        }
    };

export const fetchProjectData =
    ({ universalId, userId, includeMap, isEditPage }: ProjectFetchDataType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState) => {
        dispatch(startFetchProject());
        try {
            const {
                projects: { limits },
            } = getState();
            const project = await api.getProject(universalId);
            const pipe_details = await api.getPipesDetails(project.pipes, project);

            if (includeMap) {
                const { district_name } = project;
                const weights = getProjectWeights(project);
                dispatch(
                    fetchMapData(project, {
                        district_name,
                        weights,
                    }),
                );
            }

            if (isEditPage) {
                // You cannot edit a project if you did not create it
                const projectUserId = project.created_by_id ? `${project.created_by_id}` : null;
                if (projectUserId !== userId) {
                    dispatch(failFetchProject('You are not authorized to edit this project'));
                    return;
                }

                // You cannot edit a project if is complete
                if (project.status === PROJECT_STATUS.COMPLETE) {
                    dispatch(failFetchProject('You cannot edit a completed project'));
                    return;
                }
                project.status = PROJECT_STATUS.DRAFT as ProjectStatusType;
            }

            const profileScores =
                project.asset_category === 'Water' ? calcProfileScores(pipe_details) : initialProfileScoreState;
            dispatch(
                completeFetchProject({
                    ...project,
                    ...{ pipe_details: pipe_details },
                    ...{ profile_scores: profileScores },
                    ...{ weight_limits_warnings: getUniqueWeightLimitWarnings(project, limits) },
                }),
            );
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Failed to fetch project data.');

            dispatch(failFetchProject(message));
        }
    };

export const setMapSearchPoint =
    (payload: { value: string; label: string }): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startSetMapSearchPoint());
        try {
            const { value, label } = payload;
            const searchPoint = await api.getGeocodeResponse(label, value);
            const { extent } = searchPoint;

            dispatch(completeSetMapSearchPoint(searchPoint));
            dispatch(setMapViewport(extent));
        } catch (e: unknown) {
            const message: string =
                e instanceof AxiosError ? e.response?.data?.detail : 'Error setting map search point';

            dispatch(failSetMapSearchPoint(message));
        }
    };

export const fetchImpactScores =
    (district: DistrictType, weights: WeightsType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchImpactScores());
        try {
            const data = await api.getWaterPipesScoresData({ district }, weights);

            dispatch(
                completeFetchImpactScores({
                    district: district,
                    weights: weights,
                    scores: data.impact_scores,
                    loading: false,
                }),
            );
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Error loading impact score data.');
            dispatch(failFetchImpactScores(message));
        }
    };

export const fetchWaterPipeData =
    (district: DistrictType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchPipeData());
        try {
            const { extent, features: pipesData } = await api.getWaterPipesData({ district });
            const features = pipeFeaturesToGeojson(pipesData);

            const viewport = createViewPortFromBounds({ bounds: extent, width: mapWidth, height: mapHeight });

            dispatch(
                completeFetchPipeData({
                    pipeDataState: {
                        features: features,
                        district: district,
                        assetCategory: 'Water',
                        loading: false,
                    },
                    extent: extent,
                    viewport: viewport,
                }),
            );
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Error loading map data.');
            dispatch(failFetchPipeData(message));
        }
    };

// When we fetch wastewater data we also set the impact scores
export const fetchWastewaterPipeData =
    ({ district, wastewater_system: wasteWaterSystem, projectUniversalId }: PipesQueryType): ThunkType =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
        dispatch(startFetchPipeData());
        dispatch(startFetchImpactScores());

        try {
            const { features: pipesData, extent } = await api.getWastewaterPipesData({
                wastewater_system: wasteWaterSystem,
                district: district,
                projectUniversalId: projectUniversalId,
            });

            const features = pipeFeaturesToGeojson(pipesData);

            const impactScores = pipesData.reduce((acc: ScoresType, f: WastewaterPipeFeature) => {
                if (f.score) {
                    acc[f.gisuid] = f.score;
                }
                return acc;
            }, {});

            const viewport = createViewPortFromBounds({ bounds: extent, width: mapWidth, height: mapHeight });

            dispatch(
                completeFetchPipeData({
                    pipeDataState: {
                        features: features,
                        district: wasteWaterSystem ? wasteWaterSystem : null,
                        assetCategory: 'Wastewater',
                        loading: false,
                    },
                    extent: extent,
                    viewport: viewport,
                }),
            );

            dispatch(
                completeFetchImpactScores({
                    district: wasteWaterSystem ? wasteWaterSystem : null,
                    weights: initialState.data.impactScores.weights,
                    scores: impactScores,
                    loading: false,
                }),
            );
        } catch (e: unknown) {
            const message: string = getErrorMessage(e, 'Error loading map data.');
            dispatch(failFetchPipeData(message));
            dispatch(failFetchImpactScores(message));
        }
    };

export const selectImpactScores = ({ mapData }: RootState) => mapData.data.impactScores;
export const selectMapDataLoading = ({ mapData }: RootState) =>
    mapData.data.pipeData.loading || mapData.data.impactScores.loading;
export const selectMapDataExtent = ({ mapData }: RootState) => mapData.extent;
export const selectMapDataScored = ({ mapData }: RootState) => mapData.scored;
export const selectMapDataLoadingStartTime = ({ mapData }: RootState) => mapData.loadingStartTime;
export const selectFeaturesCount = ({ mapData }: RootState) => mapData.data.pipeData.features.length;
export default mapDataSlice.reducer;
