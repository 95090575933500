import { Heading, Flex, Spacer, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { Paths } from '../util/constants';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import { selectUser } from '../reducers/auth';
import { userCanEdit, userIsValidator } from '../util/utils';

import HeaderLink from './HeaderLink';

import { clearProject } from '../reducers/project';
import { clearMapData } from '../reducers/mapData';
import { clearFilters, setFilter, updateIsMapHidden, updateTabIndex } from '../reducers/projects';
import { fetchFilteredProjects } from '../reducers/filteredProjects';
import UserMenu from './UserMenu';

const Header = () => {
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();

    const links = [
        {
            to: Paths.explore,
            shouldDisplay: userCanEdit(user),
            onClick: () => {
                dispatch(clearFilters());
                dispatch(clearProject());
                dispatch(clearMapData());
            },
            text: 'Explore',
        },
        {
            to: Paths.newProject,
            shouldDisplay: userCanEdit(user),
            onClick: () => {
                dispatch(clearProject());
                dispatch(clearMapData());
            },
            underlineIt: true,
            text: 'New Project',
        },
        {
            to: Paths.home,
            shouldDisplay: userCanEdit(user),
            onClick: () => {
                dispatch(clearFilters());
                dispatch(
                    setFilter({
                        filterKey: 'createdByFilter',
                        selections: user ? [user.email] : [],
                    }),
                );
                dispatch(fetchFilteredProjects());
                dispatch(updateIsMapHidden(true));
                dispatch(updateTabIndex(2));
            },
            text: 'My Projects',
        },
        {
            to: Paths.home,
            shouldDisplay: userIsValidator(user),
            onClick: () => {
                dispatch(clearFilters());
                dispatch(
                    setFilter({
                        filterKey: 'projectStatusFilter',
                        selections: ['PENDING'],
                    }),
                );
                dispatch(fetchFilteredProjects());
                dispatch(updateIsMapHidden(true));
                dispatch(updateTabIndex(2));
            },
            text: 'Review Projects',
        },
        {
            to: Paths.home,
            shouldDisplay: !!user,
            onClick: () => {
                dispatch(clearFilters());
                dispatch(fetchFilteredProjects());
                dispatch(updateIsMapHidden(true));
                dispatch(updateTabIndex(2));
            },
            text: 'All Projects',
        },
        {
            to: Paths.notifications,
            shouldDisplay: userIsValidator(user),
            underlineIt: true,
            text: 'Notifications',
        },
    ];

    return (
        <Flex id="header" w="100%" p="10px" pl="25px" bg="brand.blue" color="white" alignItems="center">
            <Image src="/img/logo-amwater.png" alt="American Water Logo" h="28px" />
            <Heading fontSize="lg" ml="10px" fontWeight="semibold" as={RouterLink} to={Paths.home}>
                RP Magic
            </Heading>
            <Spacer />
            {
                <>
                    <>
                        {links.map(({ to, shouldDisplay, text, onClick, underlineIt }) => (
                            <HeaderLink
                                to={to}
                                shouldDisplay={shouldDisplay}
                                onClick={onClick}
                                text={text}
                                key={to}
                                underlineIt={underlineIt}
                            />
                        ))}
                    </>
                    <UserMenu />
                </>
            }
        </Flex>
    );
};

export default Header;
